<template>
  <div class="flex h-screen items-center" v-if="isReady">
    <div class="absolute-cover sm:static sm:w-1/2 md:w-2/3 xl:w-3/4 h-full relative bg-left-bottom 2xl:bg-left" :style="`background-image: url(${loginImage});background-size:cover;background-repeat:no-repeat;`" />
    <div class="sm:w-1/2 md:w-1/3 xl:w-1/4 mx-auto p-4 sm:p-10 2xl:p-16 z-10 h-full sm:h-auto w-full bg-white/60">
      <img alt="FOREVER® Logo" class="max-w-[184px] sm:absolute top-4 left-4 mb-6" src="https://forever-mars-ui.s3.amazonaws.com/images/forever-logo-v2.svg" />
      <h1 class="font-serif text-4xl">Welcome Back!</h1>
      <p class="mt-3 mb-8">Please enter your login credentials below.</p>
      <u-form :state="state" :schema="schema" @submit="loginUser" :validate-on="['submit']">
        <u-form-group label="Email" name="email" class="mb-6">
          <u-input v-model="state.email" autocomplete="email" />
        </u-form-group>
        <u-form-group label="Password" name="password" class="mb-6">
          <core-password-input v-model="state.password" />
          <u-button variant="link" :padded="false" :to="EXTERNAL_URLS.forgotPassword" external class="underline-on-hover">Forgot Password?</u-button>
          <u-alert
              v-if="error"
              class="mt-4"
              color="red"
              title="Login Unsuccessful"
              :description="error"
              icon="i-ri-error-warning-line"
          />
        </u-form-group>

        <u-button
          type="submit"
          :loading="isBusy"
          :icon="COMMON_ICONS.signIn"
          size="lg"
          label="Login"
        />
      </u-form>
      <hr class="my-8 sm:border-gray-200">
      <p>Need an account? <u-link :to="EXTERNAL_URLS.signUp" external class="underline-on-hover">Sign up for free.</u-link></p>

    </div>
  </div>

</template>

<script setup>
  import {object, string} from 'yup';
  import loginImage from '../assets/images/shared/login.jpg';
  import {AUTH_ON_LOGIN_REDIRECT_FALLBACK} from '../conf/app.js';

  const isReady = ref(false);

  useForeverHead({
    title: 'Log In',
  });

  const route = useRoute();
  const isBusy = ref(false);

  const schema = object({
    email: string().required('Email is required.').email('Email is invalid.'),
    password: string().required('Password is required.').min(SESSION_PASSWORD_MIN_LENGTH, `Password must be at least ${SESSION_PASSWORD_MIN_LENGTH} characters`)
  });

  const state = reactive({
    email: null,
    password: null
  });

  const error = ref(null)

  async function loginUser() {
    isBusy.value = true;
    error.value = null;

    try {
      await useSessionStore().logIn(state);

      const redirectTo = route.query.redirectTo || useSessionStore().currentUser?.landing_path || AUTH_ON_LOGIN_REDIRECT_FALLBACK;

      navigateTo(redirectTo);
    } catch (e) {
      if (e.statusCode === 400) {
        error.value = 'Invalid login credentials. Please try again.';
      } else {
        error.value = 'Something went wrong. Please try again later.'
      }
    } finally {
      isBusy.value = false;
    }
  }

  onMounted(() => {
    if (useRuntimeConfig().public.isProduction) {
      navigateTo({path: '/sign_in'}, {external: true});
    } else {
      isReady.value = true;
    }
  });


</script>
