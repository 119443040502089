<template>
  <u-input
    v-model="model"
    :ui="{ icon: { trailing: { pointer: '' } } }"
    :type="passwordType"
    autocomplete="password"
  >
    <template #trailing>
      <u-tooltip :text="passwordType === 'password' ? 'Show Password' : 'Hide Password'">
        <u-button
          color="gray"
          variant="link"
          :padded="false"
          :icon="passwordType === 'password' ? 'i-ri-eye-line' : 'i-ri-eye-off-line'"
          @click="switchVisibility"
        />
      </u-tooltip>

    </template>
  </u-input>
</template>

<script setup>
  const model = defineModel();
  const passwordType = ref('password');

  function switchVisibility() {
    passwordType.value = passwordType.value === 'text' ? 'password' : 'text';
  }
</script>
